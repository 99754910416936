import React from 'react';
import { Button, Card, Image } from 'semantic-ui-react'
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <div class="ui two column stackable center page grid">
        <div class="column">
          <div className="Contact-card">
            <Card>
              <Image src='avatar.jpg' wrapped ui={false} />
              <Card.Content>
                <Card.Header>Hunter Martin</Card.Header>
                <Card.Meta>
                  <span className='date'>Software Developer</span>
                </Card.Meta>
                <Card.Description>
                  Hunter is a Software Developer currently based in College Station, TX.  He is
                  graduating from Texas A&M University in May 2020.
                </Card.Description>
              </Card.Content>
            </Card>
          </div>
        </div>
        <div class="column">
          <div className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
              <p> Howdy!  Welcome to Hunter's React sandbox. </p>
              <p> <Button><a href="https://hunter-martin.com">go back to hunter-martin.com</a></Button> </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
